import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import {toast} from "react-toastify";
import Copyright from "./Copyright";

let sidebarDynamicHeight = '90vh';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    sidebar: {
        overflowY: 'auto',
        height: sidebarDynamicHeight,
    },
}));

export default function MenuItems(props) {
    const {menuCategories, options, clickOnCategory} = props;

    const classes = useStyles();
    const [openParent, setOpenParent] = React.useState(true);

    const handleClick = () => {
        setOpenParent(!openParent);
    };

    const [search, setSearch] = useState('');

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            toast.info("Not implemented yet...");
        }
    };

    function CustomizedListItem(props) {
        const {addKey, item} = props;
        const [open, setOpen] = React.useState(false);
        const handleClick = () => {
            setOpen(!open);
        };

        const handleCategoryClick = (subCategory, topCategory) => {
            if (!subCategory && !topCategory) return;
            clickOnCategory(subCategory, topCategory);
        };

        return (
          <>
              {
                <div key={addKey}>
                    {/*<ListItem button onClick={handleClick}>*/}
                        <ListItemText style={{marginLeft: '16px'}} className={"ttc"} primary={addKey} />
                        {/*{open ? <ExpandLess /> : <ExpandMore />}*/}
                    {/*</ListItem>*/}
                    {/*<Collapse disableStrictModeCompat in={open} timeout="auto">*/}
                        <List dense component="div" disablePadding>
                        {
                            item.map((item, index) =>
                              <ListItem
                                key={index}
                                button
                                className={classes.nested}
                                onClick={() => handleCategoryClick(item, addKey)}
                                selected={item.category === options.productLine && addKey === options.parentCategory}>
                                  <ListItemText className={"ttc"}>
                                      {item.category} {item.count > 0 ? "(" + item.count + ")" : ""}
                                  </ListItemText>
                              </ListItem>
                            )
                        }
                    </List>
                    {/*</Collapse>*/}
                </div>
              }
          </>
        )
    }

    useEffect( () => {
        let domHeader = document.querySelector('header');
        if (domHeader) { sidebarDynamicHeight = (window.innerHeight - domHeader.offsetHeight - 20) + 'px' }
    }, []);

    return (
        <List dense className={"sidebar"}>
            <div className={classes.sidebar}>
                <Box mr={2} ml={2}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="search"
                        autoComplete="off"
                        size="small"
                        placeholder={"Search..."}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>,
                        }}
                        value={search}
                        className={"search-input"}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                    />
                </Box>
                <ListItem button onClick={() => toast.info("Not implemented...")}>
                    <ListItemText primary="Dashboard" />
                </ListItem>

                {
                    menuCategories &&
                    <>
                        <ListItem button onClick={handleClick}>
                            <ListItemText primary="Competitor Intelligence" />
                            {openParent ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openParent} timeout="auto" unmountOnExit>
                            {
                                Object.keys(menuCategories).map((key) => {
                                    return (
                                      <CustomizedListItem addKey={key} item={menuCategories[key]} />
                                    )
                                })
                            }
                        </Collapse>
                    </>
                }

                <ListItem button onClick={() => toast.info("Not implemented...")}>
                    <ListItemText primary="Pricing Intelligence" />
                </ListItem>
                <ListItem button onClick={() => toast.info("Not implemented...")}>
                    <ListItemText primary="Margin Hunter" />
                </ListItem>
                <ListItem button onClick={() => toast.info("Not implemented...")}>
                    <ListItemText primary="Recommendation Engine" />
                </ListItem>
                <ListItem button onClick={() => toast.info("Not implemented...")}>
                    <ListItemText primary="Settings" />
                </ListItem>
                <ListItem button onClick={() => toast.info("Not implemented...")}>
                    <ListItemText primary="Help" />
                </ListItem>
                <Copyright/>
            </div>
        </List>
    )
}