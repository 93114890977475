import { useState, useCallback, useEffect } from 'react';
import { toast } from "react-toastify";
import {FAKE_USER_ID, STORAGE_NAME} from "../constants/app";

export const useAuth = () => {
    const [user, setUser] = useState(null);
    const [ready, setReady] = useState(false);
    const approvedUsers = [
        { email: 'demo@merchware.com', password: 'sdf6776', name: 'Thomas' }
    ];

    const login = useCallback(async (data) => {
        try {
            const confirmedUser = approvedUsers.find((user) => user.email === data.email && user.password === data.password);
            if (confirmedUser) {
                const initialUser = { id: FAKE_USER_ID, name: confirmedUser.name };
                localStorage.setItem(STORAGE_NAME, JSON.stringify(initialUser));
                setUser(initialUser);
            } else {
                toast.error("Invalid email or password. Please try again.")
            }
        } catch (error) {
            toast.error("Invalid email or password. Please try again.")
        }
    }, []);

    const logout = useCallback(() => {
        setUser(null);
        localStorage.removeItem(STORAGE_NAME);
    }, []);

    const updateStates = useCallback((userData) => {
        setUser(userData);
        localStorage.setItem(STORAGE_NAME, JSON.stringify({
            user: userData
        }))
    }, []);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(STORAGE_NAME));
        if (data && data.user) {
            updateStates(data.user)
        }
        setReady(true)
    }, [updateStates]);

    return { login, logout, user, ready }
};
