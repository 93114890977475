import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {toast} from "react-toastify";
import DialogAdditionalInfo from "./DialogAdditionalInfo";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: 'auto',
        // flex: '1 0 auto',
    },
    cover: {
        width: 151,
        backgroundSize: 'contain',
    },
    container: {
        boxShadow: '0px 0px 4px #ccc',
        overflowY: 'scroll',
        height: window.innerHeight - 190 + 'px',
    }
}));

export default function CompetitorProducts(props) {
    const classes = useStyles();
    const {competitorProducts} = props;

    return (
        <div className={classes.container}>
            <span id={"competitor-products-top"}></span>
            {
                competitorProducts.length > 0
                ?
                competitorProducts.map( (item, index) => {
                    return (
                        <Box m={1} key={item.id}>
                            <Card key={item.id} className={classes.root}>
                                <CardMedia
                                    className={classes.cover}
                                    image={item.website_image_url}
                                    title={item.product_name}
                                />
                                <CardContent className={classes.content}>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{maxWidth: '200px'}}>
                                        <b className={"ttc"}>{item.product_name || '-'}</b><br/>
                                        <span>{"Item #" + item.product_id}</span><br/>
                                        <b>Retail Price:</b> {item.cost_price ? '$' + item.cost_price : '-'}<br/>
                                        {
                                            item.in_stock && item.in_stock.length &&
                                              <span>
                                                  <span>
                                                    <b>In Stock:</b>&nbsp;<span>{item.in_stock && item.in_stock.length ? item.in_stock.join(', ') : ''}</span>
                                                              {
                                                                  item.in_stock && item.instock_num
                                                                    ? item.instock_num > 1 ? ' (' + item.instock_num + ' items)' : ' (' + item.instock_num + ' item)'
                                                                    : 'none'
                                                              }
                                                  </span>
                                                  <br/>
                                              </span>
                                        }
                                        {
                                            item.out_of_stock && item.out_of_stock.length &&
                                            <span>
                                                <b>Out of Stock:</b> {item.out_of_stock.join(', ')}<br/>
                                            </span>
                                        }

                                        {/*<b>Country of origin:</b> {item.origin_country || '-'}<br/>*/}
                                        {/*<b>Shipping time:</b> {item.shipping_time} {item.shipping_time && item.shipping_time > 1 ? 'days' : 'day'}*/}
                                        {/*<b>Product Tags:</b>&nbsp;*/}
                                        {/*{*/}
                                        {/*    item.productTags.length > 0 && item.productTags.map( (el, index) => {*/}
                                        {/*        return (*/}
                                        {/*            <span key={index}>{el}{index === item.productTags.length - 1 ? '' : ', '}</span>*/}
                                        {/*        )*/}
                                        {/*    })*/}
                                        {/*}<br/>*/}
                                    </Typography>
                                    {/*<img style={{marginTop: '5px', width: 'auto', maxWidth: '100%'}}*/}
                                    {/*     src={item.website_image_url}*/}
                                    {/*     title={item.product_name}*/}
                                    {/*     alt={item.product_name}/>*/}
                                </CardContent>
                                <CardHeader
                                    action={
                                        <DialogAdditionalInfo data={item} />
                                    }
                                    // title={item.title}
                                    // subheader={"Item #" + item.uId}
                                />
                            </Card>
                        </Box>
                    )
                })
                :
                <Box m={1}>
                    No products found...
                </Box>
            }
        </div>
    );
}