import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MainLayout from "./layouts/MainLayout";
import RetailerProducts from "../components/RetailerProducts";
import CompetitorProducts from "../components/CompetitorProducts";
import {useLocation, useHistory} from "react-router-dom";
import MenuItems from "../components/MenuItems";
import {toast} from "react-toastify";
import axios from "axios";
import Split from 'react-split';
import {DASHBOARD_SPLIT_SIZES} from "../constants/app";

let retailProductsCount = 0;

export default function Dashboard() {
    const LIMIT_OF_RETAILER_PRODUCTS = 20;
    const history = useHistory();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const initialSplitSizes = [50, 50];
    const [splitSizes] = useState(localStorage.getItem(DASHBOARD_SPLIT_SIZES) ? JSON.parse(localStorage.getItem(DASHBOARD_SPLIT_SIZES)) : initialSplitSizes);

    const [menuCategories, setMenuCategories] = useState([]);
    const [retailerProducts, setRetailerProducts] = useState([]);
    const [competitorProducts, setCompetitorProducts] = useState([]);
    const [retailerProductsOffset, setRetailerProductsOffset] = useState(0);

    const [options, setOptions] = useState({
        productLineCount: query.get('productLineCount') !== null ? query.get('productLineCount') : null,
        parentCategory: query.get('parentCategory') !== null ? query.get('parentCategory') : null,
        productLine: query.get('productLine') !== null ? query.get('productLine') : null,
        currentRetailerProductId: query.get('currentRetailerProductId') !== null ? parseInt(query.get('currentRetailerProductId')) : null
    });

    const clickOnRetailerProduct = (item) => {
        if (!item) return;
        if (item.id === options.currentRetailerProductId) return;
        getCompetitorProducts(item.id);
        setOptions({...options, currentRetailerProductId: item.id})
        scrollToElement("competitor-products-top");
    };

    async function clickOnCategory(subCategory, topCategory) {
        if (!subCategory && !topCategory) return;
        if (subCategory.category === options.productLine && topCategory === options.parentCategory) return;

        scrollToElement("competitor-products-top");
        scrollToElement("retailer-products-top");

        // Infinity scroll
        setRetailerProductsOffset(0);
        retailProductsCount = 0;
        // Infinity scroll

        let updRetailerProducts = await getRetailerProducts(subCategory.category, true, topCategory, subCategory.count);
        if (updRetailerProducts && updRetailerProducts.length > 0) {
            await getCompetitorProducts(updRetailerProducts[0].id);
        }
    };

    async function initialize() {
        let updCategories = await getAllCategories();
        if (updCategories) {

            let updRetailerProducts = await getRetailerProducts(
              query.get('productLine') && query.get('productLine') !== null
                ? query.get('productLine')
                : updCategories[Object.keys(updCategories)[0]][0].category,
              false,
              query.get('parentCategory') && query.get('parentCategory') !== null
                ? query.get('parentCategory')
                : Object.keys(updCategories)[0],
              query.get('productLineCount') && query.get('productLineCount') !== null
                ? query.get('productLineCount')
                : updCategories[Object.keys(updCategories)[0]][0].count,
            );
            if (updRetailerProducts && updRetailerProducts.length > 0) {
                await getCompetitorProducts(query.get('currentRetailerProductId') !== null ? query.get('currentRetailerProductId') : updRetailerProducts[0].id);
            }

        }
        // if (query.get('currentRetailerProductId') !== null) {
        //     scrollToElement("product-" + query.get('currentRetailerProductId'));
        // }
    }

    async function getAllCategories() {
        try {
            let apiUrl = '/api/categories';
            let response = await axios.get(apiUrl);
            if (response.status === 200 && response.data) {
                setMenuCategories(response.data.categories);

                setOptions({...options,
                    parentCategory: query.get('parentCategory') !== null ? query.get('parentCategory') : Object.keys(response.data.categories)[0],
                    productLine: query.get('productLine') !== null ? query.get('productLine') : response.data.categories[Object.keys(response.data.categories)[0]][0].category,
                    productLineCount: query.get('productLineCount') !== null ? query.get('productLineCount') : response.data.categories[Object.keys(response.data.categories)[0]][0].count,
                })

                return response.data.categories;
            }
        } catch (e) {
            console.error(e);
            if (e.response && e.response.data && e.response.data.message) toast.error(e.response.data.message);
        }
    }

    async function getRetailerProducts(subCategoryName, clickFromCategory, topCategory, subCategoryCount) {
        if (!subCategoryName) return;
        try {
            let queryOffset = clickFromCategory ? 0 : retailerProductsOffset;
            let queryUrl = '?category=' + encodeURIComponent(subCategoryName) + '&top_category=' + encodeURIComponent(topCategory) + '&limit=' + LIMIT_OF_RETAILER_PRODUCTS + '&offset=' + queryOffset;
            let apiUrl = '/api/products' + queryUrl;
            let response = await axios.get(apiUrl);
            if (response.status === 200 && response.data) {

                // Infinity scroll
                if (clickFromCategory) {
                    setRetailerProducts(response.data.items)
                } else {
                    setRetailerProducts(retailerProducts.concat(response.data.items));
                }
                // Infinity scroll

                setOptions({
                    ...options,
                    productLineCount: subCategoryCount,
                    parentCategory: topCategory,
                    productLine: subCategoryName,
                    currentRetailerProductId: clickFromCategory
                                              ? response.data.items[0].id
                                              : query.get('currentRetailerProductId') !== null
                                              ? parseInt(query.get('currentRetailerProductId'))
                                              : response.data.items[0].id
                })
                return response.data.items;
            }
        } catch (e) {
            console.error(e);
            if (e.response && e.response.data && e.response.data.message) toast.error(e.response.data.message);
        }
    }

    async function getCompetitorProducts(productId) {
        if (!productId) return;
        try {
            let apiUrl = '/api/compare/' + productId;
            let response = await axios.get(apiUrl);
            if (response.status === 200 && response.data) {
                setCompetitorProducts(response.data.items);
            }
        } catch (e) {
            console.error(e);
            if (e.response && e.response.data && e.response.data.message) toast.error(e.response.data.message);
        }
    }

    const scrollToElement = (elId) => {
        if (!elId) return;
        setTimeout(() => {
            let element = document.getElementById(elId);
            if (element) element.scrollIntoView(true);
        });
    };

    function infinityScroll() {
        let el = document.getElementById("retailer-products-container");
        if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
            setRetailerProductsOffset(retailProductsCount += LIMIT_OF_RETAILER_PRODUCTS);
            // console.log('scrolled to bottom')
        }
    }

    useEffect(() => {
        let qsParams = '';
        if (options) {
            for (let prop in options) {
                if (options[prop]) qsParams += '&' + prop + '=' + encodeURIComponent(options[prop]);
            }
        }
        let qsParamsString = qsParams.length > 0 ? qsParams.slice(1) : '';
        history.push({
            search: '?' + qsParamsString,
        });
    }, [options]);

    useEffect(function () {
        initialize();
        // Infinity scroll
        document.getElementById("retailer-products-container").addEventListener('scroll', infinityScroll);
    }, []);

    // Infinity scroll
    useEffect(() => {
        if (retailerProductsOffset > 0) {
            if (options.productLineCount <= LIMIT_OF_RETAILER_PRODUCTS) return;
            if (options.productLineCount && retailerProducts && retailerProducts.length && options.productLineCount == retailerProducts.length) return;
            getRetailerProducts(query.get('productLine') !== null
                                         ? query.get('productLine')
                                         : menuCategories[Object.keys(menuCategories)[0]][0].category,
                         false,
                              query.get('parentCategory') !== null
                                         ? query.get('parentCategory')
                                         : Object.keys(menuCategories)[0],
                          query.get('productLineCount') !== null
                                          ? query.get('productLineCount')
                                          : menuCategories[Object.keys(menuCategories)[0]][0].count,
                                         );
        }
    }, [retailerProductsOffset]);
    // Infinity scroll

    return (
        <MainLayout
            content={
                <Grid container spacing={3} style={{display: 'block', padding: '5px 10px'}}>
                    <Grid>
                        <Box mb={1}>
                            <Typography variant="h5" color="textSecondary" component="h1">
                                <b>Competitor Product Intelligence</b>
                            </Typography>
                        </Box>
                    </Grid>

                    <Split
                        sizes={splitSizes}
                        className="split"
                        minSize={300}
                        gutterSize={15}
                        onDragEnd={(coordinates) => localStorage.setItem(DASHBOARD_SPLIT_SIZES, JSON.stringify(coordinates)) }
                    >
                        <Grid>
                            <Box mb={1}>
                                <Typography variant="body1" color="textSecondary" component="h2">
                                    <b>Retailer Products: ({options.productLineCount || 0})</b>
                                </Typography>
                            </Box>
                            <RetailerProducts
                                clickOnRetailerProduct={clickOnRetailerProduct}
                                options={options}
                                retailerProducts={retailerProducts}/>
                        </Grid>

                        <Grid>
                            <Box mb={1}>
                                <Typography variant="body1" color="textSecondary" component="h2">
                                    <b>Competitor Products: {competitorProducts.length > 0 ? "(" + competitorProducts.length + ")" : ""}</b>
                                </Typography>
                            </Box>
                            <CompetitorProducts competitorProducts={competitorProducts}/>
                        </Grid>
                    </Split>

                </Grid>
            }
            sidebar={
                <MenuItems
                    clickOnCategory={clickOnCategory}
                    menuCategories={menuCategories}
                    options={options}
                />
            }
        />
    );
}