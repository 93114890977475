import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import {toast} from "react-toastify";
import clsx from 'clsx';
import DialogAdditionalInfo from "./DialogAdditionalInfo";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    container: {
        boxShadow: '0px 0px 4px #ccc',
        overflowY: 'scroll',
        height: window.innerHeight - 190 + 'px',
    }
}));

export default function RetailerProducts(props) {
    const classes = useStyles();
    const {retailerProducts, options, clickOnRetailerProduct} = props;

    const handleProductClick = (item) => {
        if (!item) return;
        clickOnRetailerProduct(item);
    };

    return (
        <div id={"retailer-products-container"} className={classes.container}>
            <span id={"retailer-products-top"}></span>
            {
                retailerProducts.length > 0
                ?
                retailerProducts.map( (item, index) => {
                    return (
                        <Box m={1} key={item.id} id={"product-" + item.id}>
                            <Card key={item.id}
                                  className={clsx(classes.root, item.id === options.currentRetailerProductId ? "selected-product" : "")}>
                                <div

                                >
                                    {/*<a onClick={() => handleProductClick(item)}>*/}
                                    {/*    <CardHeader*/}
                                    {/*      className={"custom-card-header"}*/}
                                    {/*      action={*/}
                                    {/*          <DialogAdditionalInfo data={item} />*/}
                                    {/*      }*/}
                                    {/*      title={item.product_name}*/}
                                    {/*      subheader={"Item #" + item.product_id}*/}
                                    {/*      style={{paddingBottom: '0px'}}*/}
                                    {/*    />*/}
                                    {/*</a>*/}

                                    <div>
                                        <Grid style={{flexDirection: 'row', display: 'flex'}}>
                                            <Grid item xs={12} sm={9} md={9} className={"custom-card-header"}>
                                                <a className={"cp"} style={{display: 'block', padding: '16px 0px 0px 16px'}}
                                                   onClick={() => handleProductClick(item)}>
                                                    <Typography variant="subtitle1" className={"title"} color="textSecondary" component="p">
                                                        {item.product_name}
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="textSecondary" component="p">
                                                        {"Item #" + item.product_id}
                                                    </Typography>
                                                </a>
                                            </Grid>
                                            <Grid item xs={12} sm={3} md={3} className={"d-flex justify-content-end"}>
                                                <span style={{display: 'block', padding: '8px 8px 0px 0px'}}>
                                                    <DialogAdditionalInfo data={item} />
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <a className={"cp"} onClick={() => handleProductClick(item)}>
                                        <CardContent style={{paddingTop: '16px'}}>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <span><b>Retail Price:</b> {item.cost_price ? '$' + item.cost_price : '-'}</span>
                                                <br/>
                                                {
                                                    item.in_stock && item.in_stock.length &&
                                                    <span>
                                                        <span>
                                                            <b>In Stock:</b>&nbsp;<span>{item.in_stock && item.in_stock.length ? item.in_stock.join(', ') : ''}</span>
                                                                    {
                                                                        item.in_stock && item.instock_num
                                                                          ? item.instock_num > 1 ? ' (' + item.instock_num + ' items)' : ' (' + item.instock_num + ' item)'
                                                                          : 'none'
                                                                    }
                                                        </span>
                                                        <br/>
                                                    </span>
                                                }

                                                {
                                                    item.out_of_stock && item.out_of_stock.length &&
                                                    <span>
                                                        <span><b>Out of Stock:</b> {item.out_of_stock.join(', ')}</span>
                                                        <br/>
                                                    </span>
                                                }

                                                {/*<span><b>Country of origin:</b> {item.origin_country || '-'}</span>*/}
                                                {/*<span>&nbsp;&nbsp;<b>Shipping time:</b> {item.shipping_time} {item.shipping_time && item.shipping_time > 1 ? 'days' : 'day'}</span>*/}
                                                {/*<b>Product Tags:</b>&nbsp;*/}
                                                {/*{*/}
                                                {/*    item.productTags.length > 0 && item.productTags.map( (el, index) => {*/}
                                                {/*        return (*/}
                                                {/*            <span key={index}>{el}{index === item.productTags.length - 1 ? '' : ', '}</span>*/}
                                                {/*        )*/}
                                                {/*    })*/}
                                                {/*}<br/>*/}
                                            </Typography>
                                            <img style={{marginTop: '5px', width: 'auto', maxWidth: '100%'}}
                                                 src={item.website_image_url}
                                                 title={item.item_name}
                                                 alt={item.item_name}/>
                                        </CardContent>
                                    </a>

                                    {/*<CardMedia*/}
                                    {/*    className={classes.media}*/}
                                    {/*    image={item._url}*/}
                                    {/*    title={item.item_name}*/}
                                    {/*/>*/}
                                </div>
                            </Card>
                        </Box>
                    )
                })
                :
                <Box m={1}>
                    No products found...
                </Box>
            }
        </div>
    );
}