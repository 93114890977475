import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    show: {
        display: 'block',
    },
    hide: {
        display: 'none',
    },
    loader: {
        width: '100%',
        minHeight: '100%',
        backgroundColor: '#121212',
        position: 'fixed',
        top: '0',
        left:'0',
        right: '0',
        bottom: '0',
        zIndex: '1301',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: '0.8',
    },
    loaderWrap: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        color: '#fff',
    }
}));

export default function Loader({show, textVisibility}) {
    const classes = useStyles();

    return (
        <div className={show ? classes.show : classes.hide}>
            <div className={classes.loader}>
                <div className={classes.loaderWrap}>
                    { textVisibility && <span>Please wait.<br/>Uploading in progress...</span> }
                    <CircularProgress size={80} />
                </div>
            </div>
        </div>
    );
}
