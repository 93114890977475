import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import {URL_DASHBOARD, URL_ENTRY_POINT} from "../router/urls";

// import PageNotFound from "../containers/static/PageNotFound";
import Login from "../containers/auth/Login";
import Dashboard from "../containers/Dashboard";

export const useRoutes = (isAuthenticated) => {
    if (isAuthenticated) {
        return (
            <Switch>
                <Route path={URL_DASHBOARD} exact component={Dashboard}/>

                <Redirect to={URL_DASHBOARD} />
                <Route component={Dashboard} />
            </Switch>
        )
    } else {
        return (
            <Switch>
                <Route path={URL_ENTRY_POINT} exact component={Login}/>
                <Route component={Login} />
            </Switch>
        )
    }
};



