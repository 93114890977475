import './assets/css/App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import { useRoutes } from './router';
import { useAuth } from "./hooks/auth.hook";
import {AuthContext} from "./context/AuthContext";
import Notification from "./components/Notification";
import {FAKE_USER_ID, STORAGE_NAME} from "./constants/app";
import Loader from "./components/Loader";
import {useEffect, useState} from "react";
import axios from "axios";

function App() {
    const [loader, setLoader] = useState({show: false});
    const storage = JSON.parse(localStorage.getItem(STORAGE_NAME));
    const { login, logout, user, ready } = useAuth();
    const isAuthenticated = storage && storage.id === FAKE_USER_ID ? true : false;
    const routes = useRoutes(isAuthenticated);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#35495e'
            },
        }
    });

    useEffect(function () {
        // Add a request interceptor
        axios.interceptors.request.use(function (config) {
            if (config && config.url) {
                setLoader({show: true});
            }
            return config;
        }, function (error) {
            setLoader({show: false});
            return Promise.reject(error);
        });

        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            setLoader({show: false});
            return response;
        }, async function (error) {
            setLoader({show: false});
            return Promise.reject(error);
        });
    }, [])

    if (!ready) return <Loader show={true} />

    return (
      <ThemeProvider theme={theme}>
          <AuthContext.Provider value={{ login, logout, isAuthenticated, user }}>
              <Router>
                  <Loader {...loader}/>
                  <Notification/>
                  {routes}
              </Router>
          </AuthContext.Provider>
      </ThemeProvider>
    );
}

export default App;
