import Typography from "@material-ui/core/Typography";
import React from "react";
import Box from "@material-ui/core/Box";

export default function Copyright() {
    return (
        <Box ml={2} mb={2}>
            <Typography variant="body2" style={{color: "#fff"}}>
                {'Copyright © Merchware '}
                {new Date().getFullYear()}
            </Typography>
        </Box>
    )
}