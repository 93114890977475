import {toast} from "react-toastify";

export function addErrorNotificationsOnSubmitForm(data) {
    if (data && data.length > 0) {
        let uniqueArr = [];
        uniqueArr = [...new Set(data.map(item => item.props.id))];
        uniqueArr.forEach(function (item) {
            let fieldName = item ? item : 'field';
            toast.error('Please enter a valid ' + fieldName);
        })
    } else {
        return false;
    }
}
