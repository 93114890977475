import React, { useContext, useState } from 'react';
import {IconButton, InputAdornment} from "@material-ui/core";
import { Box, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {addErrorNotificationsOnSubmitForm} from "../../helpers/app";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {AuthContext} from "../../context/AuthContext";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Copyright from "../../components/Copyright";
import logo from "../../assets/images/merchwareColorLogoUpperCaseBold.png";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(12),
        // display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export default function StudentLogin() {
    const classes = useStyles();
    const auth = useContext(AuthContext);
    const [formData, setFormData] = useState({email: '', password: ''});
    const [values, setValues] = useState({ showPassword: false });

    const handleFieldChange = event => {
        setFormData({ ...formData, [event.target.name]: event.target.value});
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function submitForm() {
        auth.login({...formData});
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>

                <Box className={"text-center"}>
                    <img src={logo} style={{maxHeight: '50px'}} alt="logo" />
                </Box>

                <ValidatorForm
                    onSubmit={submitForm}
                    onError={addErrorNotificationsOnSubmitForm}
                    instantValidate={false}
                >
                    <TextValidator
                        label="Email"
                        id={"Email"}
                        name="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        autoComplete="email"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type={"email"}
                        className={"r-field"}
                        value={formData.email}
                        validators={['required', 'isEmail']}
                        errorMessages={['This field is required', 'Email is not valid']}
                        onChange={handleFieldChange}
                    />
                    <TextValidator
                        label="Password"
                        id={"Password"}
                        name="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        autoComplete="password"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type={values.showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    size={"small"}
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                        className={"r-field r-field-with-eye"}
                        value={formData.password}
                        validators={['minStringLength:5','maxStringLength:64']}
                        errorMessages={['The password needs to be at least 5 characters', 'The password needs to be max 64 characters']}
                        onChange={handleFieldChange}
                    />
                    <Box mt={1}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Sign in
                        </Button>
                    </Box>
                </ValidatorForm>

                <Box className={"text-center light-grey-color"} m={2}>
                    <Copyright/>
                </Box>
            </div>
        </Container>

    );
}




