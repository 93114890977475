import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import axios from "axios";
import {toast} from "react-toastify";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function DialogAdditionalInfo(props) {
  const { data } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async () => {
    if (data && data.id) await getAdditionalInfo(data.id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  async function getAdditionalInfo(productId) {
    if (!productId) return;
    try {
      let apiUrl = '/api/products/' + productId;
      let response = await axios.get(apiUrl);
      if (response.status === 200 && response.data) {
        setOpen(true);
        document.getElementById("additional-info-html-content").innerHTML = response.data;
      }
    } catch (e) {
      console.error(e);
      if (e.response && e.response.data && e.response.data.message) toast.error(e.response.data.message);
    }
  }

  return (
    <div>
      <IconButton
        component={"span"}
        onClick={handleClickOpen}
        aria-label="settings">
        <MoreVertIcon />
      </IconButton>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {data && data.product_name}
        </DialogTitle>
        <DialogContent dividers>
          <div style={{minWidth: '568px', width: '100%'}} id={"additional-info-html-content"}></div>
        </DialogContent>
      </Dialog>
    </div>
  );
}